var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * bootstrap-tokenfield
 * https://github.com/sliptree/bootstrap-tokenfield
 * Copyright 2013-2014 Sliptree and other contributors; Licensed MIT
 */
(function (factory) {
  // For CommonJS and CommonJS-like environments where a window with jQuery
  // is present, execute the factory with the jQuery instance from the window object
  // For environments that do not inherently posses a window with a document
  // (such as Node.js), expose a Tokenfield-making factory as module.exports
  // This accentuates the need for the creation of a real window or passing in a jQuery instance
  // e.g. require("bootstrap-tokenfield")(window); or require("bootstrap-tokenfield")($);
  exports = _global.window && _global.window.$ ? factory(_global.window.$) : function (input) {
    if (!input.$ && !input.fn) {
      throw new Error("Tokenfield requires a window object with jQuery or a jQuery instance");
    }

    return factory(input.$ || input);
  };
})(function ($, window) {
  "use strict"; // jshint ;_;

  /* TOKENFIELD PUBLIC CLASS DEFINITION
   * ============================== */

  var Tokenfield = function (element, options) {
    var _self = this || _global;

    (this || _global).$element = $(element);
    (this || _global).textDirection = (this || _global).$element.css("direction"); // Extend options

    (this || _global).options = $.extend(true, {}, $.fn.tokenfield.defaults, {
      tokens: (this || _global).$element.val()
    }, (this || _global).$element.data(), options); // Setup delimiters and trigger keys

    (this || _global)._delimiters = typeof (this || _global).options.delimiter === "string" ? [(this || _global).options.delimiter] : (this || _global).options.delimiter;
    (this || _global)._triggerKeys = $.map((this || _global)._delimiters, function (delimiter) {
      return delimiter.charCodeAt(0);
    });
    (this || _global)._firstDelimiter = (this || _global)._delimiters[0]; // Check for whitespace, dash and special characters

    var whitespace = $.inArray(" ", (this || _global)._delimiters),
        dash = $.inArray("-", (this || _global)._delimiters);
    if (whitespace >= 0) (this || _global)._delimiters[whitespace] = "\\s";

    if (dash >= 0) {
      delete (this || _global)._delimiters[dash];

      (this || _global)._delimiters.unshift("-");
    }

    var specialCharacters = ["\\", "$", "[", "{", "^", ".", "|", "?", "*", "+", "(", ")"];
    $.each((this || _global)._delimiters, function (index, char) {
      var pos = $.inArray(char, specialCharacters);
      if (pos >= 0) _self._delimiters[index] = "\\" + char;
    }); // Store original input width

    var elRules = window && typeof window.getMatchedCSSRules === "function" ? window.getMatchedCSSRules(element) : null,
        elStyleWidth = element.style.width,
        elCSSWidth,
        elWidth = (this || _global).$element.width();

    if (elRules) {
      $.each(elRules, function (i, rule) {
        if (rule.style.width) {
          elCSSWidth = rule.style.width;
        }
      });
    } // Move original input out of the way


    var hidingPosition = $("body").css("direction") === "rtl" ? "right" : "left",
        originalStyles = {
      position: (this || _global).$element.css("position")
    };
    originalStyles[hidingPosition] = (this || _global).$element.css(hidingPosition);

    (this || _global).$element.data("original-styles", originalStyles).data("original-tabindex", (this || _global).$element.prop("tabindex")).css("position", "absolute").css(hidingPosition, "-10000px").prop("tabindex", -1); // Create a wrapper


    (this || _global).$wrapper = $("<div class=\"tokenfield form-control\" />");
    if ((this || _global).$element.hasClass("input-lg")) (this || _global).$wrapper.addClass("input-lg");
    if ((this || _global).$element.hasClass("input-sm")) (this || _global).$wrapper.addClass("input-sm");
    if ((this || _global).textDirection === "rtl") (this || _global).$wrapper.addClass("rtl"); // Create a new input

    var id = (this || _global).$element.prop("id") || new Date().getTime() + "" + Math.floor((1 + Math.random()) * 100);
    (this || _global).$input = $("<input type=\"text\" class=\"token-input\" autocomplete=\"off\" />").appendTo((this || _global).$wrapper).prop("placeholder", (this || _global).$element.prop("placeholder")).prop("id", id + "-tokenfield").prop("tabindex", (this || _global).$element.data("original-tabindex")); // Re-route original input label to new input

    var $label = $("label[for=\"" + (this || _global).$element.prop("id") + "\"]");

    if ($label.length) {
      $label.prop("for", (this || _global).$input.prop("id"));
    } // Set up a copy helper to handle copy & paste


    (this || _global).$copyHelper = $("<input type=\"text\" />").css("position", "absolute").css(hidingPosition, "-10000px").prop("tabindex", -1).prependTo((this || _global).$wrapper); // Set wrapper width

    if (elStyleWidth) {
      (this || _global).$wrapper.css("width", elStyleWidth);
    } else if (elCSSWidth) {
      (this || _global).$wrapper.css("width", elCSSWidth);
    } // If input is inside inline-form with no width set, set fixed width
    else if ((this || _global).$element.parents(".form-inline").length) {
        (this || _global).$wrapper.width(elWidth);
      } // Set tokenfield disabled, if original or fieldset input is disabled


    if ((this || _global).$element.prop("disabled") || (this || _global).$element.parents("fieldset[disabled]").length) {
      this.disable();
    } // Set tokenfield readonly, if original input is readonly


    if ((this || _global).$element.prop("readonly")) {
      this.readonly();
    } // Set up mirror for input auto-sizing


    (this || _global).$mirror = $("<span style=\"position:absolute; top:-999px; left:0; white-space:pre;\"/>");

    (this || _global).$input.css("min-width", (this || _global).options.minWidth + "px");

    $.each(["fontFamily", "fontSize", "fontWeight", "fontStyle", "letterSpacing", "textTransform", "wordSpacing", "textIndent"], function (i, val) {
      _self.$mirror[0].style[val] = _self.$input.css(val);
    });

    (this || _global).$mirror.appendTo("body"); // Insert tokenfield to HTML


    (this || _global).$wrapper.insertBefore((this || _global).$element);

    (this || _global).$element.prependTo((this || _global).$wrapper); // Calculate inner input width


    this.update(); // Create initial tokens, if any

    this.setTokens((this || _global).options.tokens, false, false); // Start listening to events

    this.listen(); // Initialize autocomplete, if necessary

    if (!$.isEmptyObject((this || _global).options.autocomplete)) {
      var side = (this || _global).textDirection === "rtl" ? "right" : "left",
          autocompleteOptions = $.extend({
        minLength: (this || _global).options.showAutocompleteOnFocus ? 0 : null,
        position: {
          my: side + " top",
          at: side + " bottom",
          of: (this || _global).$wrapper
        }
      }, (this || _global).options.autocomplete);

      (this || _global).$input.autocomplete(autocompleteOptions);
    } // Initialize typeahead, if necessary


    if (!$.isEmptyObject((this || _global).options.typeahead)) {
      var typeaheadOptions = (this || _global).options.typeahead,
          defaults = {
        minLength: (this || _global).options.showAutocompleteOnFocus ? 0 : null
      },
          args = $.isArray(typeaheadOptions) ? typeaheadOptions : [typeaheadOptions, typeaheadOptions];
      args[0] = $.extend({}, defaults, args[0]);

      (this || _global).$input.typeahead.apply((this || _global).$input, args);

      (this || _global).typeahead = true;
    }

    (this || _global).$element.trigger("tokenfield:initialize");
  };

  Tokenfield.prototype = {
    constructor: Tokenfield,
    createToken: function (attrs, triggerChange) {
      var _self = this || _global;

      if (typeof attrs === "string") {
        attrs = {
          value: attrs,
          label: attrs
        };
      }

      if (typeof triggerChange === "undefined") {
        triggerChange = true;
      } // Normalize label and value


      attrs.value = $.trim(attrs.value);
      attrs.label = attrs.label && attrs.label.length ? $.trim(attrs.label) : attrs.value; // Bail out if has no value or label, or label is too short

      if (!attrs.value.length || !attrs.label.length || attrs.label.length <= (this || _global).options.minLength) return; // Bail out if maximum number of tokens is reached

      if ((this || _global).options.limit && this.getTokens().length >= (this || _global).options.limit) return; // Allow changing token data before creating it

      var createEvent = $.Event("tokenfield:createtoken", {
        attrs: attrs
      });

      (this || _global).$element.trigger(createEvent); // Bail out if there if attributes are empty or event was defaultPrevented


      if (!createEvent.attrs || createEvent.isDefaultPrevented()) return;
      var $token = $("<div class=\"token\" />").attr("data-value", attrs.value).append("<span class=\"token-label\" />").append("<a href=\"#\" class=\"close\" tabindex=\"-1\">&times;</a>"); // Insert token into HTML

      if ((this || _global).$input.hasClass("tt-input")) {
        // If the input has typeahead enabled, insert token before it's parent
        (this || _global).$input.parent().before($token);
      } else {
        (this || _global).$input.before($token);
      } // Temporarily set input width to minimum


      (this || _global).$input.css("width", (this || _global).options.minWidth + "px");

      var $tokenLabel = $token.find(".token-label"),
          $closeButton = $token.find(".close"); // Determine maximum possible token label width

      if (!(this || _global).maxTokenWidth) {
        (this || _global).maxTokenWidth = (this || _global).$wrapper.width() - $closeButton.outerWidth() - parseInt($closeButton.css("margin-left"), 10) - parseInt($closeButton.css("margin-right"), 10) - parseInt($token.css("border-left-width"), 10) - parseInt($token.css("border-right-width"), 10) - parseInt($token.css("padding-left"), 10) - parseInt($token.css("padding-right"), 10);
        parseInt($tokenLabel.css("border-left-width"), 10) - parseInt($tokenLabel.css("border-right-width"), 10) - parseInt($tokenLabel.css("padding-left"), 10) - parseInt($tokenLabel.css("padding-right"), 10);
        parseInt($tokenLabel.css("margin-left"), 10) - parseInt($tokenLabel.css("margin-right"), 10);
      }

      $tokenLabel.text(attrs.label).css("max-width", (this || _global).maxTokenWidth); // Listen to events on token

      $token.on("mousedown", function (e) {
        if (_self._disabled || _self._readonly) return false;
        _self.preventDeactivation = true;
      }).on("click", function (e) {
        if (_self._disabled || _self._readonly) return false;
        _self.preventDeactivation = false;

        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          return _self.toggle($token);
        }

        _self.activate($token, e.shiftKey, e.shiftKey);
      }).on("dblclick", function (e) {
        if (_self._disabled || _self._readonly || !_self.options.allowEditing) return false;

        _self.edit($token);
      });
      $closeButton.on("click", $.proxy((this || _global).remove, this || _global)); // Trigger createdtoken event on the original field
      // indicating that the token is now in the DOM

      (this || _global).$element.trigger($.Event("tokenfield:createdtoken", {
        attrs: attrs,
        relatedTarget: $token.get(0)
      })); // Trigger change event on the original field


      if (triggerChange) {
        (this || _global).$element.val(this.getTokensList()).trigger($.Event("change", {
          initiator: "tokenfield"
        }));
      } // Update tokenfield dimensions


      this.update(); // Return original element

      return (this || _global).$element.get(0);
    },
    setTokens: function (tokens, add, triggerChange) {
      if (!tokens) return;
      if (!add) (this || _global).$wrapper.find(".token").remove();

      if (typeof triggerChange === "undefined") {
        triggerChange = true;
      }

      if (typeof tokens === "string") {
        if ((this || _global)._delimiters.length) {
          // Split based on delimiters
          tokens = tokens.split(new RegExp("[" + (this || _global)._delimiters.join("") + "]"));
        } else {
          tokens = [tokens];
        }
      }

      var _self = this || _global;

      $.each(tokens, function (i, attrs) {
        _self.createToken(attrs, triggerChange);
      });
      return (this || _global).$element.get(0);
    },
    getTokenData: function ($token) {
      var data = $token.map(function () {
        var $token = $(this || _global);
        return {
          value: $token.attr("data-value"),
          label: $token.find(".token-label").text()
        };
      }).get();

      if (data.length == 1) {
        data = data[0];
      }

      return data;
    },
    getTokens: function (active) {
      var self = this || _global,
          tokens = [],
          activeClass = active ? ".active" : ""; // get active tokens only

      (this || _global).$wrapper.find(".token" + activeClass).each(function () {
        tokens.push(self.getTokenData($(this || _global)));
      });

      return tokens;
    },
    getTokensList: function (delimiter, beautify, active) {
      delimiter = delimiter || (this || _global)._firstDelimiter;
      beautify = typeof beautify !== "undefined" && beautify !== null ? beautify : (this || _global).options.beautify;
      var separator = delimiter + (beautify && delimiter !== " " ? " " : "");
      return $.map(this.getTokens(active), function (token) {
        return token.value;
      }).join(separator);
    },
    getInput: function () {
      return (this || _global).$input.val();
    },
    listen: function () {
      var _self = this || _global;

      (this || _global).$element.on("change", $.proxy((this || _global).change, this || _global));

      (this || _global).$wrapper.on("mousedown", $.proxy((this || _global).focusInput, this || _global));

      (this || _global).$input.on("focus", $.proxy((this || _global).focus, this || _global)).on("blur", $.proxy((this || _global).blur, this || _global)).on("paste", $.proxy((this || _global).paste, this || _global)).on("keydown", $.proxy((this || _global).keydown, this || _global)).on("keypress", $.proxy((this || _global).keypress, this || _global)).on("keyup", $.proxy((this || _global).keyup, this || _global));

      (this || _global).$copyHelper.on("focus", $.proxy((this || _global).focus, this || _global)).on("blur", $.proxy((this || _global).blur, this || _global)).on("keydown", $.proxy((this || _global).keydown, this || _global)).on("keyup", $.proxy((this || _global).keyup, this || _global)); // Secondary listeners for input width calculation


      (this || _global).$input.on("keypress", $.proxy((this || _global).update, this || _global)).on("keyup", $.proxy((this || _global).update, this || _global));

      (this || _global).$input.on("autocompletecreate", function () {
        // Set minimum autocomplete menu width
        var $_menuElement = $(this || _global).data("ui-autocomplete").menu.element;
        var minWidth = _self.$wrapper.outerWidth() - parseInt($_menuElement.css("border-left-width"), 10) - parseInt($_menuElement.css("border-right-width"), 10);
        $_menuElement.css("min-width", minWidth + "px");
      }).on("autocompleteselect", function (e, ui) {
        if (_self.createToken(ui.item)) {
          _self.$input.val("");

          if (_self.$input.data("edit")) {
            _self.unedit(true);
          }
        }

        return false;
      }).on("typeahead:selected typeahead:autocompleted", function (e, datum, dataset) {
        // Create token
        if (_self.createToken(datum)) {
          _self.$input.typeahead("val", "");

          if (_self.$input.data("edit")) {
            _self.unedit(true);
          }
        }
      }); // Listen to window resize


      $(window).on("resize", $.proxy((this || _global).update, this || _global));
    },
    keydown: function (e) {
      if (!(this || _global).focused) return;

      var _self = this || _global;

      switch (e.keyCode) {
        case 8:
          // backspace
          if (!(this || _global).$input.is(document.activeElement)) break;
          (this || _global).lastInputValue = (this || _global).$input.val();
          break;

        case 37:
          // left arrow
          leftRight((this || _global).textDirection === "rtl" ? "next" : "prev");
          break;

        case 38:
          // up arrow
          upDown("prev");
          break;

        case 39:
          // right arrow
          leftRight((this || _global).textDirection === "rtl" ? "prev" : "next");
          break;

        case 40:
          // down arrow
          upDown("next");
          break;

        case 65:
          // a (to handle ctrl + a)
          if ((this || _global).$input.val().length > 0 || !(e.ctrlKey || e.metaKey)) break;
          this.activateAll();
          e.preventDefault();
          break;

        case 9: // tab

        case 13:
          // enter     
          // We will handle creating tokens from autocomplete in autocomplete events
          if ((this || _global).$input.data("ui-autocomplete") && (this || _global).$input.data("ui-autocomplete").menu.element.find("li:has(a.ui-state-focus)").length) break; // We will handle creating tokens from typeahead in typeahead events

          if ((this || _global).$input.hasClass("tt-input") && (this || _global).$wrapper.find(".tt-cursor").length) break;
          if ((this || _global).$input.hasClass("tt-input") && (this || _global).$wrapper.find(".tt-hint").val().length) break; // Create token

          if ((this || _global).$input.is(document.activeElement) && (this || _global).$input.val().length || (this || _global).$input.data("edit")) {
            return this.createTokensFromInput(e, (this || _global).$input.data("edit"));
          } // Edit token


          if (e.keyCode === 13) {
            if (!(this || _global).$copyHelper.is(document.activeElement) || (this || _global).$wrapper.find(".token.active").length !== 1) break;
            if (!_self.options.allowEditing) break;
            this.edit((this || _global).$wrapper.find(".token.active"));
          }

      }

      function leftRight(direction) {
        if (_self.$input.is(document.activeElement)) {
          if (_self.$input.val().length > 0) return;
          direction += "All";
          var $token = _self.$input.hasClass("tt-input") ? _self.$input.parent()[direction](".token:first") : _self.$input[direction](".token:first");
          if (!$token.length) return;
          _self.preventInputFocus = true;
          _self.preventDeactivation = true;

          _self.activate($token);

          e.preventDefault();
        } else {
          _self[direction](e.shiftKey);

          e.preventDefault();
        }
      }

      function upDown(direction) {
        if (!e.shiftKey) return;

        if (_self.$input.is(document.activeElement)) {
          if (_self.$input.val().length > 0) return;
          var $token = _self.$input.hasClass("tt-input") ? _self.$input.parent()[direction + "All"](".token:first") : _self.$input[direction + "All"](".token:first");
          if (!$token.length) return;

          _self.activate($token);
        }

        var opposite = direction === "prev" ? "next" : "prev",
            position = direction === "prev" ? "first" : "last";

        _self.firstActiveToken[opposite + "All"](".token").each(function () {
          _self.deactivate($(this || _global));
        });

        _self.activate(_self.$wrapper.find(".token:" + position), true, true);

        e.preventDefault();
      }

      (this || _global).lastKeyDown = e.keyCode;
    },
    keypress: function (e) {
      (this || _global).lastKeyPressCode = e.keyCode;
      (this || _global).lastKeyPressCharCode = e.charCode; // Comma

      if ($.inArray(e.charCode, (this || _global)._triggerKeys) !== -1 && (this || _global).$input.is(document.activeElement)) {
        if ((this || _global).$input.val()) {
          this.createTokensFromInput(e);
        }

        return false;
      }
    },
    keyup: function (e) {
      (this || _global).preventInputFocus = false;
      if (!(this || _global).focused) return;

      switch (e.keyCode) {
        case 8:
          // backspace
          if ((this || _global).$input.is(document.activeElement)) {
            if ((this || _global).$input.val().length || (this || _global).lastInputValue.length && (this || _global).lastKeyDown === 8) break;
            (this || _global).preventDeactivation = true;
            var $prevToken = (this || _global).$input.hasClass("tt-input") ? (this || _global).$input.parent().prevAll(".token:first") : (this || _global).$input.prevAll(".token:first");
            if (!$prevToken.length) break;
            this.activate($prevToken);
          } else {
            this.remove(e);
          }

          break;

        case 46:
          // delete
          this.remove(e, "next");
          break;
      }

      (this || _global).lastKeyUp = e.keyCode;
    },
    focus: function (e) {
      (this || _global).focused = true;

      (this || _global).$wrapper.addClass("focus");

      if ((this || _global).$input.is(document.activeElement)) {
        (this || _global).$wrapper.find(".active").removeClass("active");

        (this || _global).$firstActiveToken = null;

        if ((this || _global).options.showAutocompleteOnFocus) {
          this.search();
        }
      }
    },
    blur: function (e) {
      (this || _global).focused = false;

      (this || _global).$wrapper.removeClass("focus");

      if (!(this || _global).preventDeactivation && !(this || _global).$element.is(document.activeElement)) {
        (this || _global).$wrapper.find(".active").removeClass("active");

        (this || _global).$firstActiveToken = null;
      }

      if (!(this || _global).preventCreateTokens && ((this || _global).$input.data("edit") && !(this || _global).$input.is(document.activeElement) || (this || _global).options.createTokensOnBlur)) {
        this.createTokensFromInput(e);
      }

      (this || _global).preventDeactivation = false;
      (this || _global).preventCreateTokens = false;
    },
    paste: function (e) {
      var _self = this || _global; // Add tokens to existing ones


      setTimeout(function () {
        _self.createTokensFromInput(e);
      }, 1);
    },
    change: function (e) {
      if (e.initiator === "tokenfield") return; // Prevent loops

      this.setTokens((this || _global).$element.val());
    },
    createTokensFromInput: function (e, focus) {
      if ((this || _global).$input.val().length < (this || _global).options.minLength) return; // No input, simply return

      var tokensBefore = this.getTokensList();
      this.setTokens((this || _global).$input.val(), true);
      if (tokensBefore == this.getTokensList() && (this || _global).$input.val().length) return false; // No tokens were added, do nothing (prevent form submit)

      if ((this || _global).$input.hasClass("tt-input")) {
        // Typeahead acts weird when simply setting input value to empty,
        // so we set the query to empty instead
        (this || _global).$input.typeahead("val", "");
      } else {
        (this || _global).$input.val("");
      }

      if ((this || _global).$input.data("edit")) {
        this.unedit(focus);
      }

      return false; // Prevent form being submitted
    },
    next: function (add) {
      if (add) {
        var $firstActiveToken = (this || _global).$wrapper.find(".active:first"),
            deactivate = $firstActiveToken && (this || _global).$firstActiveToken ? $firstActiveToken.index() < (this || _global).$firstActiveToken.index() : false;

        if (deactivate) return this.deactivate($firstActiveToken);
      }

      var $lastActiveToken = (this || _global).$wrapper.find(".active:last"),
          $nextToken = $lastActiveToken.nextAll(".token:first");

      if (!$nextToken.length) {
        (this || _global).$input.focus();

        return;
      }

      this.activate($nextToken, add);
    },
    prev: function (add) {
      if (add) {
        var $lastActiveToken = (this || _global).$wrapper.find(".active:last"),
            deactivate = $lastActiveToken && (this || _global).$firstActiveToken ? $lastActiveToken.index() > (this || _global).$firstActiveToken.index() : false;

        if (deactivate) return this.deactivate($lastActiveToken);
      }

      var $firstActiveToken = (this || _global).$wrapper.find(".active:first"),
          $prevToken = $firstActiveToken.prevAll(".token:first");

      if (!$prevToken.length) {
        $prevToken = (this || _global).$wrapper.find(".token:first");
      }

      if (!$prevToken.length && !add) {
        (this || _global).$input.focus();

        return;
      }

      this.activate($prevToken, add);
    },
    activate: function ($token, add, multi, remember) {
      if (!$token) return;
      if (typeof remember === "undefined") var remember = true;
      if (multi) var add = true;

      (this || _global).$copyHelper.focus();

      if (!add) {
        (this || _global).$wrapper.find(".active").removeClass("active");

        if (remember) {
          (this || _global).$firstActiveToken = $token;
        } else {
          delete (this || _global).$firstActiveToken;
        }
      }

      if (multi && (this || _global).$firstActiveToken) {
        // Determine first active token and the current tokens indicies
        // Account for the 1 hidden textarea by subtracting 1 from both
        var i = (this || _global).$firstActiveToken.index() - 2,
            a = $token.index() - 2,
            _self = this || _global;

        (this || _global).$wrapper.find(".token").slice(Math.min(i, a) + 1, Math.max(i, a)).each(function () {
          _self.activate($(this || _global), true);
        });
      }

      $token.addClass("active");

      (this || _global).$copyHelper.val(this.getTokensList(null, null, true)).select();
    },
    activateAll: function () {
      var _self = this || _global;

      (this || _global).$wrapper.find(".token").each(function (i) {
        _self.activate($(this || _global), i !== 0, false, false);
      });
    },
    deactivate: function ($token) {
      if (!$token) return;
      $token.removeClass("active");

      (this || _global).$copyHelper.val(this.getTokensList(null, null, true)).select();
    },
    toggle: function ($token) {
      if (!$token) return;
      $token.toggleClass("active");

      (this || _global).$copyHelper.val(this.getTokensList(null, null, true)).select();
    },
    edit: function ($token) {
      if (!$token) return;
      var attrs = {
        value: $token.data("value"),
        label: $token.find(".token-label").text()
      }; // Allow changing input value before editing

      var options = {
        attrs: attrs,
        relatedTarget: $token.get(0)
      };
      var editEvent = $.Event("tokenfield:edittoken", options);

      (this || _global).$element.trigger(editEvent); // Edit event can be cancelled if default is prevented


      if (editEvent.isDefaultPrevented()) return;
      $token.find(".token-label").text(attrs.value);
      var tokenWidth = $token.outerWidth();
      var $_input = (this || _global).$input.hasClass("tt-input") ? (this || _global).$input.parent() : (this || _global).$input;
      $token.replaceWith($_input);
      (this || _global).preventCreateTokens = true;

      (this || _global).$input.val(attrs.value).select().data("edit", true).width(tokenWidth);

      this.update(); // Indicate that token in snow being edited, and is replaced with an input field in the DOM

      (this || _global).$element.trigger($.Event("tokenfield:editedtoken", options));
    },
    unedit: function (focus) {
      var $_input = (this || _global).$input.hasClass("tt-input") ? (this || _global).$input.parent() : (this || _global).$input;
      $_input.appendTo((this || _global).$wrapper);

      (this || _global).$input.data("edit", false);

      (this || _global).$mirror.text("");

      this.update(); // Because moving the input element around in DOM 
      // will cause it to lose focus, we provide an option
      // to re-focus the input after appending it to the wrapper

      if (focus) {
        var _self = this || _global;

        setTimeout(function () {
          _self.$input.focus();
        }, 1);
      }
    },
    remove: function (e, direction) {
      if ((this || _global).$input.is(document.activeElement) || (this || _global)._disabled || (this || _global)._readonly) return;
      var $token = e.type === "click" ? $(e.target).closest(".token") : (this || _global).$wrapper.find(".token.active");

      if (e.type !== "click") {
        if (!direction) var direction = "prev";
        this[direction](); // Was it the first token?

        if (direction === "prev") var firstToken = $token.first().prevAll(".token:first").length === 0;
      } // Prepare events and their options


      var options = {
        attrs: this.getTokenData($token),
        relatedTarget: $token.get(0)
      },
          removeEvent = $.Event("tokenfield:removetoken", options);

      (this || _global).$element.trigger(removeEvent); // Remove event can be intercepted and cancelled


      if (removeEvent.isDefaultPrevented()) return;
      var removedEvent = $.Event("tokenfield:removedtoken", options),
          changeEvent = $.Event("change", {
        initiator: "tokenfield"
      }); // Remove token from DOM

      $token.remove(); // Trigger events

      (this || _global).$element.val(this.getTokensList()).trigger(removedEvent).trigger(changeEvent); // Focus, when necessary:
      // When there are no more tokens, or if this was the first token
      // and it was removed with backspace or it was clicked on


      if (!(this || _global).$wrapper.find(".token").length || e.type === "click" || firstToken) (this || _global).$input.focus(); // Adjust input width

      (this || _global).$input.css("width", (this || _global).options.minWidth + "px");

      this.update(); // Cancel original event handlers

      e.preventDefault();
      e.stopPropagation();
    }
    /**
     * Update tokenfield dimensions
     */
    ,
    update: function (e) {
      var value = (this || _global).$input.val(),
          inputPaddingLeft = parseInt((this || _global).$input.css("padding-left"), 10),
          inputPaddingRight = parseInt((this || _global).$input.css("padding-right"), 10),
          inputPadding = inputPaddingLeft + inputPaddingRight;

      if ((this || _global).$input.data("edit")) {
        if (!value) {
          value = (this || _global).$input.prop("placeholder");
        }

        if (value === (this || _global).$mirror.text()) return;

        (this || _global).$mirror.text(value);

        var mirrorWidth = (this || _global).$mirror.width() + 10;

        if (mirrorWidth > (this || _global).$wrapper.width()) {
          return (this || _global).$input.width((this || _global).$wrapper.width());
        }

        (this || _global).$input.width(mirrorWidth);
      } else {
        (this || _global).$input.css("width", (this || _global).options.minWidth + "px");

        if ((this || _global).textDirection === "rtl") {
          return (this || _global).$input.width((this || _global).$input.offset().left + (this || _global).$input.outerWidth() - (this || _global).$wrapper.offset().left - parseInt((this || _global).$wrapper.css("padding-left"), 10) - inputPadding - 1);
        }

        (this || _global).$input.width((this || _global).$wrapper.offset().left + (this || _global).$wrapper.width() + parseInt((this || _global).$wrapper.css("padding-left"), 10) - (this || _global).$input.offset().left - inputPadding);
      }
    },
    focusInput: function (e) {
      if ($(e.target).closest(".token").length || $(e.target).closest(".token-input").length || $(e.target).closest(".tt-dropdown-menu").length) return; // Focus only after the current call stack has cleared,
      // otherwise has no effect.
      // Reason: mousedown is too early - input will lose focus
      // after mousedown. However, since the input may be moved
      // in DOM, there may be no click or mouseup event triggered.

      var _self = this || _global;

      setTimeout(function () {
        _self.$input.focus();
      }, 0);
    },
    search: function () {
      if ((this || _global).$input.data("ui-autocomplete")) {
        (this || _global).$input.autocomplete("search");
      }
    },
    disable: function () {
      this.setProperty("disabled", true);
    },
    enable: function () {
      this.setProperty("disabled", false);
    },
    readonly: function () {
      this.setProperty("readonly", true);
    },
    writeable: function () {
      this.setProperty("readonly", false);
    },
    setProperty: function (property, value) {
      (this || _global)["_" + property] = value;

      (this || _global).$input.prop(property, value);

      (this || _global).$element.prop(property, value);

      (this || _global).$wrapper[value ? "addClass" : "removeClass"](property);
    },
    destroy: function () {
      // Set field value
      (this || _global).$element.val(this.getTokensList()); // Restore styles and properties


      (this || _global).$element.css((this || _global).$element.data("original-styles"));

      (this || _global).$element.prop("tabindex", (this || _global).$element.data("original-tabindex")); // Re-route tokenfield labele to original input


      var $label = $("label[for=\"" + (this || _global).$input.prop("id") + "\"]");

      if ($label.length) {
        $label.prop("for", (this || _global).$element.prop("id"));
      } // Move original element outside of tokenfield wrapper


      (this || _global).$element.insertBefore((this || _global).$wrapper); // Remove tokenfield-related data


      (this || _global).$element.removeData("original-styles").removeData("original-tabindex").removeData("bs.tokenfield"); // Remove tokenfield from DOM


      (this || _global).$wrapper.remove();

      var $_element = (this || _global).$element;
      delete (this || _global);
      return $_element;
    }
  };
  /* TOKENFIELD PLUGIN DEFINITION
   * ======================== */

  var old = $.fn.tokenfield;

  $.fn.tokenfield = function (option, param) {
    var value,
        args = [];
    Array.prototype.push.apply(args, arguments);
    var elements = this.each(function () {
      var $this = $(this || _global),
          data = $this.data("bs.tokenfield"),
          options = typeof option == "object" && option;

      if (typeof option === "string" && data && data[option]) {
        args.shift();
        value = data[option].apply(data, args);
      } else {
        if (!data && typeof option !== "string" && !param) $this.data("bs.tokenfield", data = new Tokenfield(this || _global, options));
      }
    });
    return typeof value !== "undefined" ? value : elements;
  };

  $.fn.tokenfield.defaults = {
    minWidth: 60,
    minLength: 0,
    allowEditing: true,
    limit: 0,
    autocomplete: {},
    typeahead: {},
    showAutocompleteOnFocus: false,
    createTokensOnBlur: false,
    delimiter: ",",
    beautify: true
  };
  $.fn.tokenfield.Constructor = Tokenfield;
  /* TOKENFIELD NO CONFLICT
   * ================== */

  $.fn.tokenfield.noConflict = function () {
    $.fn.tokenfield = old;
    return this || _global;
  };

  return Tokenfield;
});

export default exports;